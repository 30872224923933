.servicii {
  height: calc(100vh - 250px);
  background-image: linear-gradient(rgb(59, 59, 59), rgb(160, 160, 160));
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}

.firstList {
  display: flex;
  justify-content: center;
}

.title {
  color: white;
  padding: 10px 0 10px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  font-size: x-large;
}

.serviciu {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: space-around;
}

.img {
  width: 100px;
  height: 100px;
  margin: 20px 20px 0 20px;
  border-radius: 5px;
}

.img:hover {
  cursor: pointer;
  border-radius: 50%;
}

.text {
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .servicii {
    height: calc(100vh - 460px);
  }
  .img {
    width: 30px;
    height: 30px;
  }
  .title {
    font-size: smaller;
  }
  .text {
    font-size: small;
  }
  .serviciu {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
  }
}
