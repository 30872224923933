.button {
  border: none;
  background: none;
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
}

.button:hover {
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  color: rgb(29, 158, 40);
}

.button:active {
  transform: translateY(5px);
}

@media only screen and (max-width: 850px) {
  .button {
    border: none;
    background: none;
    font-size: 1rem;
    color: white;
    font-weight: bold;
  }
}
